import React from "react";
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { processOfferData } from "./functions";

import Config from "../../config/config";

import "./styles.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const CashBidTable = ({ data }) => {
	const { offers, commodities, cme } = data;
	const categories = processOfferData(offers, commodities, cme);
	const wheatCategories = categories?.filter(commodity => commodity.name === "Wheat");
	const soybeanCategories = categories?.filter(commodity => commodity.name === "Soybeans");

	const filterWheatCategoriesByType = wheatCategories?.map(wheatCategory => {
		return wheatCategory?.offers?.map(offer => {
			return {
				type: offer.commodity.type || "-",
				...offer
			}
		})
	})

	const filterSoybeanCategoriesByType = soybeanCategories?.map(soybeanCategory => {
		return soybeanCategory?.offers?.map(offer => {
			return {
				type: offer.commodity.type || "-",
				...offer
			}
		})
	})

	const filterElevators = (elevatorName) => {
		if (process.env.REACT_APP_ELEVATOR_FILTER) {
			return elevatorName === process.env.REACT_APP_ELEVATOR_FILTER
		} else if (process.env.REACT_APP_SLG_DRAIN_BROS_FILTER || process.env.REACT_APP_SLG_STOUFFVILLE_FILTER) {
			return elevatorName === process.env.REACT_APP_SLG_DRAIN_BROS_FILTER || elevatorName === process.env.REACT_APP_SLG_STOUFFVILLE_FILTER
		}
		return elevatorName
	}

	function compare(a, b) {
		if (process.env.REACT_APP_WHEAT_SORT_BY_SRW === 'true') {
			if (a.commodity.type === "SRW" && b.commodity.type !== "SRW") return -1
		} else if (process.env.REACT_APP_SLG_STOUFFVILLE_FILTER) {
			if (a.address.name === process.env.REACT_APP_SLG_STOUFFVILLE_FILTER && b.address.name !== process.env.REACT_APP_SLG_STOUFFVILLE_FILTER) return -1
		}
		return 1
	}
	const renderOffers = offers => {
		return offers?.filter(offer => filterElevators(offer.address.name)).sort((a, b) => compare(a, b)).map(offer => {
			const isNegative = offer?.priceDetails?.change < 0;
			const isPositive = offer?.priceDetails?.change > 0;

			return (
				<Tr key={offer.id} style={{ background: Config.styles.surface }}>
					{process.env.REACT_APP_DISPLAY_CROP_YEAR === 'true' && (
						<Td>
							<div>
								<span>{offer.cropYear}</span>
							</div>
						</Td>
					)}
					{process.env.REACT_APP_HIDE_TYPE_COLUMN !== 'true' && (
						<Td>
							<div>
								<span className="highlighted">{`${offer.commodity.type} - ${offer.commodity.grade.grade}`}</span>
							</div>
						</Td>
					)}
					{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
						<Td>
							<div>
								<span className="highlighted">{`${offer.comments}`}</span>
							</div>
						</Td>
					)}
					<Td>
						<div>
							<span>
								{moment(offer?.deliveryStartDateTime).format("MMM-YYYY") === moment(offer?.deliveryEndDateTime).format("MMM-YYYY")
									? moment(offer?.deliveryStartDateTime).format("MMM-YYYY")
									: `${moment(offer?.deliveryStartDateTime).format("MMM-YYYY")} to ${moment(offer.deliveryEndDateTime).format("MMM-YYYY")}`}
							</span>
						</div>
					</Td>
					{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
						<Td>
							<div>
								<span style={offer.address && offer.address.name === process.env.REACT_APP_SLG_STOUFFVILLE_FILTER ? { 'fontWeight': 700 } : null}>{offer.address ? offer.address.name : "-"}</span>
							</div>
						</Td>
					)}
					<Td>
						<div>
							<span>{`${offer?.commodity?.future?.month || ''} ${offer?.futureYear || ''}`}</span>
						</div>
					</Td>
					<Td>
						<div>
							<span>{offer?.priceDetails?.futurePrice || '-'}</span>
						</div>
					</Td>
					<Td>
						<div>
							<span
								className={`${isNegative && "negative"} ${isPositive && "positive"
									}`}
							>
								{offer?.priceDetails?.change}
							</span>
						</div>
					</Td>
					{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
						<Td>
							<div>
								<span>{offer?.priceDetails?.basisPrice || ''}</span>
							</div>
						</Td>)}
					{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
						<Td>
							<div>
								<span className="highlighted">{offer?.priceDetails?.priceBu}</span>
							</div>
						</Td>
					)}
					<Td>
						<div>
							<span className="highlighted">{offer?.priceDetails?.priceMt}</span>
						</div>
					</Td>
					{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
						<Td>
							<div>
								<span className="highlighted">{offer?.priceDetails?.priceBu}</span>
							</div>
						</Td>
					)}
				</Tr>
			);
		});
	};

	const filterWheatBids = (cats) => {
		if (process.env.REACT_APP_SPLIT_WHEAT_BIDS === 'true') {
			return cats.filter(cat => cat?.name !== "Wheat")
		}
		return cats
	}

	const filterSoybeanBids = (cats) => {
		if (process.env.REACT_APP_SPLIT_SOYBEAN_BIDS === 'true') {
			return cats.filter(cat => cat?.name !== "Soybeans")
		}
		return cats
	}

	return (
		<div className="align-items-center cash-bid-container">
			<div className="mb-5 align-items-center">
				<div className="card-body">
					<div className="table-responsive">
						{categories && filterWheatBids(filterSoybeanBids(categories)).map((category) => (
							<div key={category.name}>
								<div className="cash-bid-table_title">
									<img
										src={category.icon}
										className="cash-bid-table_commodityIcon"
										alt="commodity-icon"
									/>
									<span style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>{category.name}</span>
								</div>
								<Table className="table table-bordered cash-bid-table">
									<Thead className="table-dark">
										<Tr>
											{process.env.REACT_APP_DISPLAY_CROP_YEAR === 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Crop Year
												</Th>
											)}
											{process.env.REACT_APP_HIDE_TYPE_COLUMN !== 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
											)}
											{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Comments
												</Th>
											)}
											<Th
												scope="col"
												style={{ background: Config.styles.primaryColor }}
											>
												Delivery Period
											</Th>
											{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Destination
												</Th>
											)}
											<Th
												scope="col"
												style={{ background: Config.styles.primaryColor }}
											>
												Future Month
											</Th>
											<Th
												scope="col"
												style={{ background: Config.styles.primaryColor }}
											>
												Futures
											</Th>
											<Th
												scope="col"
												style={{ background: Config.styles.primaryColor }}
											>
												Change
											</Th>
											{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Basis
												</Th>)}
											{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/BU)
												</Th>
											)}
											<Th
												scope="col"
												style={{ background: Config.styles.primaryColor }}
											>
												Price ($/MT)
											</Th>
											{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/BU)
												</Th>
											)}
										</Tr>
									</Thead>
									<Tbody>{renderOffers(category.offers)}</Tbody>
								</Table>
							</div>
						))}
						{(categories?.length > 0 && process.env.REACT_APP_SPLIT_SOYBEAN_BIDS === 'true') && (
							<>
								<div className="cash-bid-table_title">
									<img
										src="https://storage.googleapis.com/gd_public_assets/soybeans.png"
										className="cash-bid-table_commodityIcon"
										alt="commodity-icon"
									/>
									<span>Soybeans</span>
								</div>
								<div>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Crush (RR)"))}</Tbody>
									</Table>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Non-GM"))}</Tbody>
									</Table>
									{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Meal"))?.length > 0 && (
										<Table className="table table-bordered cash-bid-table">
											<Thead className="table-dark">
												<Tr>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Type
													</Th>
													{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Comments
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Delivery Period
													</Th>
													{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Destination
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Future Month
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Futures
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Change
													</Th>
													{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Basis
														</Th>)}
													{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/MT)
													</Th>
													{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Meal"))}</Tbody>
										</Table>
									)}
									{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Oil"))?.length > 0 && (
										<Table className="table table-bordered cash-bid-table">
											<Thead className="table-dark">
												<Tr>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Type
													</Th>
													{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Comments
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Delivery Period
													</Th>
													{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Destination
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Future Month
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Futures
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Change
													</Th>
													{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Basis
														</Th>)}
													{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/MT)
													</Th>
													{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "Oil"))}</Tbody>
										</Table>
									)}
									{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "IP"))?.length > 0 && (
										<Table className="table table-bordered cash-bid-table">
											<Thead className="table-dark">
												<Tr>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Type
													</Th>
													{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Comments
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Delivery Period
													</Th>
													{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Destination
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Future Month
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Futures
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Change
													</Th>
													{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Basis
														</Th>)}
													{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/MT)
													</Th>
													{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "IP"))}</Tbody>
										</Table>
									)}
									{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "SQWH"))?.length > 0 && (
										<Table className="table table-bordered cash-bid-table">
											<Thead className="table-dark">
												<Tr>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Type
													</Th>
													{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Comments
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Delivery Period
													</Th>
													{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Destination
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Future Month
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Futures
													</Th>
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Change
													</Th>
													{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Basis
														</Th>)}
													{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/MT)
													</Th>
													{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
														<Th
															scope="col"
															style={{ background: Config.styles.primaryColor }}
														>
															Price ($/BU)
														</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>{filterSoybeanCategoriesByType.length > 0 && renderOffers(filterSoybeanCategoriesByType[0]?.filter(offer => offer?.type === "SQWH"))}</Tbody>
										</Table>
									)}

								</div>
							</>
						)}
						{(categories?.length > 0 && process.env.REACT_APP_SPLIT_WHEAT_BIDS === 'true') && (
							<>
								<div className="cash-bid-table_title">
									<img
										src="https://storage.googleapis.com/gd_public_assets/wheat.png"
										className="cash-bid-table_commodityIcon"
										alt="commodity-icon"
									/>
									<span>Wheat</span>
								</div>

								<div>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterWheatCategoriesByType.length > 0 && renderOffers(filterWheatCategoriesByType[0]?.filter(offer => offer?.type === "SRW"))}</Tbody>
									</Table>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterWheatCategoriesByType.length > 0 && renderOffers(filterWheatCategoriesByType[0]?.filter(offer => offer?.type === "HRW"))}</Tbody>
									</Table>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterWheatCategoriesByType.length > 0 && renderOffers(filterWheatCategoriesByType[0]?.filter(offer => offer?.type === "HRS"))}</Tbody>
									</Table>
									<Table className="table table-bordered cash-bid-table">
										<Thead className="table-dark">
											<Tr>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Type
												</Th>
												{process.env.REACT_APP_COMMENTS_COLUMN === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Comments
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Delivery Period
												</Th>
												{process.env.REACT_APP_HIDE_DESTINATION === 'false' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Destination
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Future Month
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Futures
												</Th>
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Change
												</Th>
												{process.env.REACT_APP_HIDE_BASIS_COLUMN !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Basis
													</Th>)}
												{process.env.REACT_APP_SWITCH_MT_BU !== 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
												<Th
													scope="col"
													style={{ background: Config.styles.primaryColor }}
												>
													Price ($/MT)
												</Th>
												{process.env.REACT_APP_SWITCH_MT_BU === 'true' && (
													<Th
														scope="col"
														style={{ background: Config.styles.primaryColor }}
													>
														Price ($/BU)
													</Th>
												)}
											</Tr>
										</Thead>
										<Tbody>{filterWheatCategoriesByType.length > 0 && renderOffers(filterWheatCategoriesByType[0]?.filter(offer => offer?.type === "SWW"))}</Tbody>
									</Table>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CashBidTable;
