import React from "react";
import BeanImage from "../../../assets/images/backgrounds/ploverBeans.jpg";
import PloverStory from "../../../assets/images/backgrounds/ploverMillsStoryBackground.jpg";
import PloverSoybeans from '../../../assets/images/backgrounds/ploverSoybeans.jpg';
import Config from "../../config";

function Products() {
	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: Config.styles.backgroundColor }}
		>
			<ProductSection
				img={BeanImage}
				title="Prograin Zeta"
				bulletPoints={[
					"$5.00 premium",
					"2875 chu",
					"SCN Resistant",
					"Semi bushy",
					"Medium tall plant",
					"Excellent yield potental",
				]}
			/>
			<ProductSection
				img={PloverStory}
				title="Prograin Wilma"
				bulletPoints={[
					"$4.50 premium",
					"2750 chu",
					"Top performer at OS acc trials",
					"Performs well in all row widths",
					"Phytophthora protection",
				]}
			/>
			<ProductSection
				img={PloverSoybeans}
				title="Silverline S07-M8"
				bulletPoints={[
					"$5.00 premium",
					"2725 chu",
					"White mould tolerant",
					"Tall plant with good standability",
					"Excellent yield rating",
				]}
			/>
			<div className="flex flex-col justify-center items-center font-semibold">
				<p>Black beans contracts also available</p>
				<p>IP beans all received at Plover Mills Commodities</p>
				<p>Black bean receiving at Patterson Grain site</p>
				<p>Prograin seed dealers for both IP and GMO beans</p>
				<p>2025 seed Prograin released in October</p>
				<p>Call to discuss</p>
			</div>
		</div>
	);
}

function ProductSection(props) {
	return (
		<div className="flex flex-col-reverse tablet:flex-row py-4">
			<img
				className="rounded-lg shadow-md h-80  tablet:w-80 object-cover"
				src={props.img}
				alt={props.title}
			></img>
			<div className="pb-10 tablet:pb-0 tablet:pl-10">
				<h2
					className="text-4xl font-bold"
					style={{ color: Config.styles.primaryColor }}
				>
					{props.title}
				</h2>

				<p className="max-w-2xl text-xl pt-4">{props.description}</p>
				{typeof props.bulletPoints !== "undefined" && (
					<ul className="pl-10 pt-2">
						{props.bulletPoints.map((points) => (
							<li key={points} className="list-disc text-gray-600">
								{points}
							</li>
						))}
					</ul>
				)}
			</div>

			{typeof props.trailingImage !== "undefined" && (
				<img
					className="pb-10 tablet:pb-0 tablet:pl-10"
					src={props.trailingImage}
					alt="test"
				></img>
			)}
		</div>
	);
}
export default Products;
